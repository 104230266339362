import React, { FC } from 'react'
import { ThemeColors } from 'atomic/styles/theme.css'
import { Book } from './Book'
import { Delivery } from './Delivery'
import { Flower } from './Flower'
import { Glasses } from './Glasses'
import { Peace } from './Peace'
import { Hearts } from './Hearts'
import { Stars3 } from './Stars3'
import { CreditCard } from './CreditCard'
import { Carrot } from './Carrot'
import { Chat } from './Chat'
import { Clipboard } from './Clipboard'
import { Cloud } from './Cloud'
import { Cookie } from './Cookie'
import { Cup } from './Cup'
import { Discount25 } from './Discount25'
import { GirlWithKey } from './GirlWithKey'
import { HeartHug } from './HeartHug'
import { GirlWithShoppingbag } from './GirlWithShoppingbag'
import { Arms2 } from './Arms2'
import { GirlEating } from './GirlEating'
import { QuotationMarks } from './QuotationMarks'
import { PeopleOnSkateboard } from './PeopleOnSkateboard'
import { PeopleSharing } from './PeopleSharing'
import { GuyMeditating } from './GuyMeditating'
import { Gift } from './Gift'
import { Gift2 } from './Gift2'
import { GiftPile } from './GiftPile'
import { GiftPile2 } from './GiftPile2'
import { Hand4 } from './Hand4'
import { RockOn } from './RockOn'
import { Hand } from './Hand'
import { Hand2 } from './Hand2'
import { Hand3 } from './Hand3'
import { Heart } from './Heart'
import { Icecream } from './Icecream'
import { Key } from './Key'
import { Lamp } from './Lamp'
import { Lemon } from './Lemon'
import { Mail } from './Mail'
import { Megaphone } from './Megaphone'
import { Moon } from './Moon'
import { Music } from './Music'
import { Pen } from './Pen'
import { Camera } from './Camera'
import { Jar } from './Jar'
import { Plant } from './Plant'
import { Prints } from './Prints'
import { Lock } from './Lock'
import { Santa } from './Santa'
import { Satellite } from './Satellite'
import { Sausage } from './Sausage'
import { Save } from './Save'
import { Computer } from './Computer'
import { Tools } from './Tools'
import { ShoppingBag } from './ShoppingBag'
import { RollerSkate } from './RollerSkate'
import { Star } from './Star'
import { Stars1 } from './Stars1'
import { Stars2 } from './Stars2'
import { Clock } from './Clock'
import { Cake } from './Cake'
import { Cardboard } from './Cardboard'
import { Scissor } from './Scissor'
import { Stars4 } from './Stars4'
import { Strawberry } from './Strawberry'
import { Bell } from './Bell'
import { Arms } from './Arms'
import { Sun } from './Sun'
import { Tea } from './Tea'
import { Diamond } from './Diamond'
import { Ufo } from './Ufo'
import { Highfive } from './Highfive'
import { Wifi } from './Wifi'
import { Write } from './Write'
import { EarthGirl } from './EarthGirl'
import { Arrow01 } from './Arrow01'
import { Arrow02 } from './Arrow02'
import { Arrow03 } from './Arrow03'
import { Arrow04 } from './Arrow04'
import { Arrow05 } from './Arrow05'
import { Arrow06 } from './Arrow06'
import { Arrow07 } from './Arrow07'
import { Arrow08 } from './Arrow08'
import { Arrow09 } from './Arrow09'
import { Arrow10 } from './Arrow10'
import { Kludd01 } from './Kludd01'
import { Kludd02 } from './Kludd02'
import { Kludd03 } from './Kludd03'
import { Kludd04 } from './Kludd04'
import { Kludd05 } from './Kludd05'
import { Kludd06 } from './Kludd06'
import { Kludd07 } from './Kludd07'
import { Kludd08 } from './Kludd08'
import { Kludd09 } from './Kludd09'
import { Kludd10 } from './Kludd10'
import { Kludd11 } from './Kludd11'
import { Kludd12 } from './Kludd12'
import { Kludd13 } from './Kludd13'
import { Kludd14 } from './Kludd14'
import { Kludd15 } from './Kludd15'
import { Kludd16 } from './Kludd16'
import { Kludd17 } from './Kludd17'
import { Kludd18 } from './Kludd18'
import { Kludd19 } from './Kludd19'
import { SpeechBubble } from './SpeechBubble'
import { TwoPeople } from './TwoPeople'
import { SatisfactionHand } from './SatisfactionHand'
import { Underline } from './Underline'
import { Discount25Off } from './Discount25Off'
import { BookWithKludd } from './BookWithKludd'
import { LampWithKludd } from './LampWithKludd'
import { Money } from './Money'
import { Discount40 } from './Discount40'

export const illustrationTypes = [
  'arms',
  'arms-2',
  'arrow-1',
  'arrow-2',
  'arrow-3',
  'arrow-4',
  'arrow-5',
  'arrow-6',
  'arrow-7',
  'arrow-8',
  'arrow-9',
  'arrow-10',
  'bell',
  'book',
  'book-with-kludd',
  'cake',
  'camera',
  'cardboard',
  'carrot',
  'chat',
  'clipboard',
  'clock',
  'cloud',
  'computer',
  'cookie',
  'credit-card',
  'cup',
  'delivery',
  'diamond',
  'discount-25',
  'discount-40',
  'discount-25-off',
  'earth-girl',
  'flower',
  'gift',
  'gift-2',
  'gift-pile',
  'gift-pile-2',
  'girl-eating',
  'girl-with-key',
  'girl-with-shoppingbag',
  'glasses',
  'guy-meditating',
  'hand',
  'hand-2',
  'hand-3',
  'hand-4',
  'heart',
  'hearts',
  'heart-hug',
  'highfive',
  'icecream',
  'jar',
  'key',
  'kludd-1',
  'kludd-2',
  'kludd-3',
  'kludd-4',
  'kludd-5',
  'kludd-6',
  'kludd-7',
  'kludd-8',
  'kludd-9',
  'kludd-10',
  'kludd-11',
  'kludd-12',
  'kludd-13',
  'kludd-14',
  'kludd-15',
  'kludd-16',
  'kludd-17',
  'kludd-18',
  'kludd-19',
  'lamp',
  'lamp-with-kludd',
  'lemon',
  'lock',
  'mail',
  'megaphone',
  'money',
  'moon',
  'music',
  'peace',
  'pen',
  'people-on-skateboard',
  'people-sharing',
  'plant',
  'prints',
  'quotation-marks',
  'rock-on',
  'roller-skate',
  'santa',
  'satellite',
  'satisfaction-hand',
  'sausage',
  'save',
  'scissor',
  'shopping-bag',
  'speech-bubble',
  'star',
  'stars-1',
  'stars-2',
  'stars-3',
  'stars-4',
  'strawberry',
  'sun',
  'tea',
  'tools',
  'two-people',
  'ufo',
  'underline',
  'wifi',
  'write',
] as const

export type IllustrationType = (typeof illustrationTypes)[number]

export type IllustrationPropTypes = {
  name: IllustrationType
  color?: keyof ThemeColors
  width?: string | number
  height?: string | number
  className?: string
  style?: React.CSSProperties
}

const Illustrations: FC<IllustrationPropTypes> = ({ name, color, className, width, height, style }) => {
  switch (name) {
    case 'arms':
      return <Arms className={className} color={color} width={width} height={height} />
    case 'arms-2':
      return <Arms2 className={className} color={color} width={width} height={height} />
    case 'arrow-1':
      return <Arrow01 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-2':
      return <Arrow02 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-3':
      return <Arrow03 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-4':
      return <Arrow04 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-5':
      return <Arrow05 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-6':
      return <Arrow06 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-7':
      return <Arrow07 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-8':
      return <Arrow08 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-9':
      return <Arrow09 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-10':
      return <Arrow10 className={className} color={color} width={width} height={height} style={style} />
    case 'bell':
      return <Bell className={className} color={color} width={width} height={height} />
    case 'book':
      return <Book className={className} color={color} width={width} height={height} />
    case 'book-with-kludd':
      return <BookWithKludd className={className} color={color} width={width} height={height} />
    case 'cake':
      return <Cake className={className} color={color} width={width} height={height} />
    case 'camera':
      return <Camera className={className} color={color} width={width} height={height} />
    case 'cardboard':
      return <Cardboard className={className} color={color} width={width} height={height} />
    case 'carrot':
      return <Carrot className={className} color={color} width={width} height={height} />
    case 'chat':
      return <Chat className={className} color={color} width={width} height={height} />
    case 'clipboard':
      return <Clipboard className={className} color={color} width={width} height={height} />
    case 'clock':
      return <Clock className={className} color={color} width={width} height={height} />
    case 'cloud':
      return <Cloud className={className} color={color} width={width} height={height} />
    case 'computer':
      return <Computer className={className} color={color} width={width} height={height} />
    case 'cookie':
      return <Cookie className={className} color={color} width={width} height={height} />
    case 'credit-card':
      return <CreditCard className={className} color={color} width={width} height={height} />
    case 'cup':
      return <Cup className={className} color={color} width={width} height={height} />
    case 'delivery':
      return <Delivery className={className} color={color} width={width} height={height} />
    case 'diamond':
      return <Diamond className={className} color={color} width={width} height={height} />
    case 'discount-25':
      return <Discount25 className={className} color={color} width={width} height={height} />
    case 'discount-40':
      return <Discount40 className={className} color={color} width={width} height={height} />
    case 'discount-25-off':
      return <Discount25Off className={className} color={color} width={width} height={height} />
    case 'earth-girl':
      return <EarthGirl className={className} color={color} width={width} height={height} />
    case 'flower':
      return <Flower className={className} color={color} width={width} height={height} />
    case 'gift':
      return <Gift className={className} color={color} width={width} height={height} />
    case 'gift-2':
      return <Gift2 className={className} color={color} width={width} height={height} />
    case 'gift-pile':
      return <GiftPile className={className} color={color} width={width} height={height} />
    case 'gift-pile-2':
      return <GiftPile2 className={className} color={color} width={width} height={height} />
    case 'girl-eating':
      return <GirlEating className={className} color={color} width={width} height={height} />
    case 'girl-with-key':
      return <GirlWithKey className={className} color={color} width={width} height={height} />
    case 'girl-with-shoppingbag':
      return <GirlWithShoppingbag className={className} color={color} width={width} height={height} />
    case 'glasses':
      return <Glasses className={className} color={color} width={width} height={height} />
    case 'guy-meditating':
      return <GuyMeditating className={className} color={color} width={width} height={height} />
    case 'hand':
      return <Hand className={className} color={color} width={width} height={height} />
    case 'hand-2':
      return <Hand2 className={className} color={color} width={width} height={height} />
    case 'hand-3':
      return <Hand3 className={className} color={color} width={width} height={height} />
    case 'hand-4':
      return <Hand4 className={className} color={color} width={width} height={height} />
    case 'heart':
      return <Heart className={className} color={color} width={width} height={height} />
    case 'hearts':
      return <Hearts className={className} color={color} width={width} height={height} />
    case 'heart-hug':
      return <HeartHug className={className} color={color} width={width} height={height} />
    case 'highfive':
      return <Highfive className={className} color={color} width={width} height={height} />
    case 'icecream':
      return <Icecream className={className} color={color} width={width} height={height} />
    case 'jar':
      return <Jar className={className} color={color} width={width} height={height} />
    case 'key':
      return <Key className={className} color={color} width={width} height={height} />
    case 'kludd-1':
      return <Kludd01 className={className} color={color} width={width} height={height} />
    case 'kludd-2':
      return <Kludd02 className={className} color={color} width={width} height={height} />
    case 'kludd-3':
      return <Kludd03 className={className} color={color} width={width} height={height} />
    case 'kludd-4':
      return <Kludd04 className={className} color={color} width={width} height={height} />
    case 'kludd-5':
      return <Kludd05 className={className} color={color} width={width} height={height} />
    case 'kludd-6':
      return <Kludd06 className={className} color={color} width={width} height={height} />
    case 'kludd-7':
      return <Kludd07 className={className} color={color} width={width} height={height} />
    case 'kludd-8':
      return <Kludd08 className={className} color={color} width={width} height={height} />
    case 'kludd-9':
      return <Kludd09 className={className} color={color} width={width} height={height} />
    case 'kludd-10':
      return <Kludd10 className={className} color={color} width={width} height={height} />
    case 'kludd-11':
      return <Kludd11 className={className} color={color} width={width} height={height} />
    case 'kludd-12':
      return <Kludd12 className={className} color={color} width={width} height={height} />
    case 'kludd-13':
      return <Kludd13 className={className} color={color} width={width} height={height} />
    case 'kludd-14':
      return <Kludd14 className={className} color={color} width={width} height={height} />
    case 'kludd-15':
      return <Kludd15 className={className} color={color} width={width} height={height} />
    case 'kludd-16':
      return <Kludd16 className={className} color={color} width={width} height={height} />
    case 'kludd-17':
      return <Kludd17 className={className} color={color} width={width} height={height} />
    case 'kludd-18':
      return <Kludd18 className={className} color={color} width={width} height={height} />
    case 'kludd-19':
      return <Kludd19 className={className} color={color} width={width} height={height} />
    case 'lamp':
      return <Lamp className={className} color={color} width={width} height={height} />
    case 'lamp-with-kludd':
      return <LampWithKludd className={className} color={color} width={width} height={height} />
    case 'lemon':
      return <Lemon className={className} color={color} width={width} height={height} />
    case 'lock':
      return <Lock className={className} color={color} width={width} height={height} />
    case 'mail':
      return <Mail className={className} color={color} width={width} height={height} />
    case 'megaphone':
      return <Megaphone className={className} color={color} width={width} height={height} />
    case 'money':
      return <Money className={className} color={color} width={width} height={height} />
    case 'moon':
      return <Moon className={className} color={color} width={width} height={height} />
    case 'music':
      return <Music className={className} color={color} width={width} height={height} />
    case 'peace':
      return <Peace className={className} color={color} width={width} height={height} />
    case 'pen':
      return <Pen className={className} color={color} width={width} height={height} />
    case 'people-on-skateboard':
      return <PeopleOnSkateboard className={className} color={color} width={width} height={height} />
    case 'people-sharing':
      return <PeopleSharing className={className} color={color} width={width} height={height} />
    case 'plant':
      return <Plant className={className} color={color} width={width} height={height} />
    case 'prints':
      return <Prints className={className} color={color} width={width} height={height} />
    case 'quotation-marks':
      return <QuotationMarks className={className} color={color} width={width} height={height} />
    case 'rock-on':
      return <RockOn className={className} color={color} width={width} height={height} />
    case 'roller-skate':
      return <RollerSkate className={className} color={color} width={width} height={height} />
    case 'santa':
      return <Santa className={className} color={color} width={width} height={height} />
    case 'satellite':
      return <Satellite className={className} color={color} width={width} height={height} />
    case 'satisfaction-hand':
      return <SatisfactionHand className={className} color={color} width={width} height={height} />
    case 'sausage':
      return <Sausage className={className} color={color} width={width} height={height} />
    case 'save':
      return <Save className={className} color={color} width={width} height={height} />
    case 'scissor':
      return <Scissor className={className} color={color} width={width} height={height} />
    case 'shopping-bag':
      return <ShoppingBag className={className} color={color} width={width} height={height} />
    case 'speech-bubble':
      return <SpeechBubble className={className} color={color} width={width} height={height} />
    case 'star':
      return <Star className={className} color={color} width={width} height={height} />
    case 'stars-1':
      return <Stars1 className={className} color={color} width={width} height={height} />
    case 'stars-2':
      return <Stars2 className={className} color={color} width={width} height={height} />
    case 'stars-3':
      return <Stars3 className={className} color={color} width={width} height={height} />
    case 'stars-4':
      return <Stars4 className={className} color={color} width={width} height={height} />
    case 'strawberry':
      return <Strawberry className={className} color={color} width={width} height={height} />
    case 'sun':
      return <Sun className={className} color={color} width={width} height={height} />
    case 'tea':
      return <Tea className={className} color={color} width={width} height={height} />
    case 'tools':
      return <Tools className={className} color={color} width={width} height={height} />
    case 'two-people':
      return <TwoPeople className={className} color={color} width={width} height={height} />
    case 'ufo':
      return <Ufo className={className} color={color} width={width} height={height} />
    case 'underline':
      return <Underline className={className} color={color} width={width} height={height} />
    case 'wifi':
      return <Wifi className={className} color={color} width={width} height={height} />
    case 'write':
      return <Write className={className} color={color} width={width} height={height} />
    default:
      return null
  }
}

export default Illustrations
