import React, { FC } from 'react'
import { Box, Carousel, Container, Heading, Text } from 'atomic'
import { ThemeColors, ThemeSpaces, ouTheme } from 'atomic/styles/theme.css'
import Image from 'next/image'
import Button, { ButtonType } from 'atomic/components/atoms/button/Button'
import { Link } from 'components/link/Link'
import * as css from './TextImageComponent.css'
import Illustration, { IllustrationType } from 'atomic/components/atoms/illustrations/Illustration'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import FloatingIllustrations from '../momsstory_components/floating_illustrations/FloatingIllustrations'

type TextListItem = {
  subheading: string
  paragraph: string
  richTextParagraph: string
  useRichTextEditor: boolean
  showAsIngress?: boolean
  centerText: boolean
}

type ButtonItem = {
  text: string
  type: ButtonType
  link: string
  target: boolean
  size: 'l' | 'm' | 's'
}

type ImageItem = {
  src: string
  maxHeight: number
  roundedCorners: number
}

type List = {
  dotColor: keyof ThemeColors
  startIndex?: number
  listItem: ListItem[]
  title: string
}

type ListItem = {
  text: string
}

type Slide = {
  src: string
  roundedCorners: number
  maxHeight: number
}

type TextImageComponentProps = {
  fullbleedLayout?: boolean
  useFullbleedImage?: boolean
  heading: string
  addHeadingLink?: boolean
  headingLink?: string
  invertedColor?: boolean
  ingress: string
  addIllustration?: boolean
  illustration: IllustrationType
  textList: TextListItem[]
  addList?: boolean
  list?: List
  addSlideshow?: boolean
  slides?: Slide[]
  paginatorColor: 'light' | 'dark'
  paginatorPosition: 'floating' | 'bottom'
  image: ImageItem
  illustrationInImage?: 'version1' | 'version2'
  mirroredLayout?: boolean
  columnLayout?: boolean
  backgroundCheck?: boolean
  color: keyof ThemeColors
  extraMarginBottom: boolean
  extraMarginBottomSize: keyof ThemeSpaces
  addButton?: boolean
  button?: ButtonItem
  children: React.ReactNode
} & BuilderProps

const TextImageComponent: FC<TextImageComponentProps> = ({
  fullbleedLayout = false,
  useFullbleedImage = false,
  heading,
  addHeadingLink = false,
  headingLink,
  invertedColor = false,
  ingress,
  addIllustration = false,
  illustration,
  textList,
  addList = false,
  list,
  addSlideshow,
  slides,
  paginatorColor = 'dark',
  paginatorPosition = 'floating',
  image,
  illustrationInImage,
  mirroredLayout = false,
  columnLayout = false,
  backgroundCheck = false,
  color,
  extraMarginBottom = false,
  extraMarginBottomSize = 'xl',
  addButton = false,
  button,
  children,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <>
      {!useFullbleedImage && (
        <Box
          backgroundColor={backgroundCheck ? color : undefined}
          marginX={backgroundCheck && !fullbleedLayout ? { mobile: 'none', desktop: 'xxl' } : {}}
          paddingY={backgroundCheck ? 'xxl' : 'none'}
          id={addHeadingLink ? headingLink : undefined}
          {...cleanProps}
        >
          <Container
            position={'relative'}
            display={'flex'}
            flexDirection={{ mobile: 'column', desktop: columnLayout ? 'column-reverse' : mirroredLayout ? 'row-reverse' : 'row' }}
            alignItems={'center'}
            style={{ width: '100%' }}
            gap={{ mobile: 'l', desktop: 'none' }}
          >
            <Box display={{ mobile: 'flex', desktop: 'none' }} alignItems={'center'} justifyContent={'center'} style={{ width: '100%' }}>
              {slides && (
                <Carousel
                  paginatorColor={paginatorColor}
                  paginatorPosition={paginatorPosition}
                  items={slides?.map((slide, index) => {
                    return (
                      <Image
                        key={index}
                        src={slide.src}
                        alt={`carousel_image_${index}`}
                        width={0}
                        height={0}
                        sizes="100vw"
                        style={{
                          width: '100%',
                          height: 'auto',
                          maxHeight: slide.maxHeight,
                          borderRadius: slide.roundedCorners,
                          marginLeft: '2.5%',
                        }}
                        draggable={false}
                        priority
                      />
                    )
                  })}
                />
              )}
              {image && !addSlideshow && (
                <Image
                  src={image?.src}
                  alt={heading}
                  width={0}
                  height={0}
                  sizes="100vw"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: 390,
                    borderRadius: image?.roundedCorners,
                  }}
                />
              )}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={columnLayout ? 'center' : 'flex-start'}
              gap={'l'}
              style={{ width: '100%', maxWidth: columnLayout ? 715 : undefined }}
              paddingRight={{ mobile: 'none', desktop: !mirroredLayout && backgroundCheck ? 'xxxl' : 'none' }}
              paddingLeft={{ mobile: 'none', desktop: mirroredLayout && !backgroundCheck ? 'xxxl' : mirroredLayout ? 'xxl' : 'none' }}
            >
              <Box display={'flex'} flexDirection={'column'} alignItems={columnLayout ? 'center' : 'flex-start'} gap={ingress ? 'l' : 'xxs'}>
                <Box display={'flex'} flexDirection={'column'} gap={'m'}>
                  {addIllustration && <Illustration name={illustration ?? 'camera'} width={100} />}
                  {heading && (
                    <Heading as={'h2'} fontSize={'xl'} marginBottom={'none'} color={invertedColor ? 'white' : 'malm0'}>
                      {heading}
                    </Heading>
                  )}
                  {ingress && (
                    <Text fontSize={'l'} fontWeight={'light'} marginBottom={'none'} color={invertedColor ? 'white' : 'malm0'}>
                      {ingress}
                    </Text>
                  )}
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={'m'}>
                  {textList &&
                    textList.map((item, index) => (
                      <Box key={index} display={'flex'} flexDirection={'column'} gap={'xxs'}>
                        <Text
                          fontSize={'base'}
                          fontWeight={'semibold'}
                          marginBottom={'none'}
                          style={{ maxWidth: 430 }}
                          textAlign={item.centerText ? 'center' : 'left'}
                          color={invertedColor ? 'white' : 'malm0'}
                        >
                          {item.subheading}
                        </Text>
                        {!item.useRichTextEditor ? (
                          <Text
                            fontSize={item.showAsIngress ? 'l' : 'base'}
                            fontWeight={'light'}
                            textAlign={item.centerText ? 'center' : 'left'}
                            color={invertedColor ? 'white' : 'malm0'}
                          >
                            {item.paragraph}
                          </Text>
                        ) : (
                          <Box
                            fontSize={item.showAsIngress ? 'l' : 'base'}
                            fontWeight={'light'}
                            marginBottom={'none'}
                            textAlign={item.centerText ? 'center' : 'left'}
                            color={invertedColor ? 'white' : 'malm0'}
                            dangerouslySetInnerHTML={{ __html: item.richTextParagraph }}
                          />
                        )}
                      </Box>
                    ))}
                </Box>
              </Box>
              {addButton && (
                <Box>
                  <Link href={button?.link} target={button?.target ? '_blank' : undefined} style={{ outline: 'none' }}>
                    <Button
                      variant={button?.type}
                      size={button?.size}
                      style={
                        (invertedColor && button?.type === 'secondary') || button?.type === 'toggle'
                          ? { borderColor: 'white', color: 'white', outline: 'none' }
                          : invertedColor && button?.type === 'primary'
                          ? { backgroundColor: 'white', color: '#222', outline: 'none' }
                          : { outline: 'none' }
                      }
                    >
                      {button?.text}
                    </Button>
                  </Link>
                </Box>
              )}
              {addList && list && list.listItem && (
                <Box display={'flex'} flexDirection={'column'} gap={'xxs'}>
                  <Text fontSize={'l'} fontWeight={'bold'} className={css.listText} color={invertedColor ? 'white' : 'malm0'}>
                    {list.title}
                  </Text>
                  {list.listItem.map((item, index) => (
                    <Box key={index} display={'flex'} alignItems={'flex-start'} gap={'s'}>
                      <Box background={list.dotColor} alignItems={'center'} justifyContent={'center'} textAlign={'center'} className={css.dot}>
                        <Text fontFamily="accent" fontWeight={'semibold'} className={css.dotText}>
                          {index + list.startIndex}
                        </Text>
                      </Box>
                      <Box
                        key={index}
                        fontSize={'base'}
                        fontWeight={'light'}
                        className={css.listText}
                        color={invertedColor ? 'white' : 'malm0'}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </Box>
                  ))}
                </Box>
              )}
              {children}
            </Box>
            <Box
              display={{ mobile: 'none', desktop: 'flex' }}
              paddingRight={{ mobile: 'none', desktop: mirroredLayout && backgroundCheck ? 'xxl' : 'none' }}
              paddingLeft={{ mobile: 'none', desktop: !mirroredLayout && !columnLayout ? 'xxl' : 'none' }}
              style={{ width: '100%' }}
              justifyContent={columnLayout ? 'center' : undefined}
            >
              {slides && (
                <Carousel
                  paginatorColor={paginatorColor}
                  paginatorPosition={paginatorPosition}
                  items={slides?.map((slide, index) => {
                    return (
                      <Image
                        key={index}
                        src={slide.src}
                        alt={`carousel_image_${index}`}
                        width={0}
                        height={0}
                        sizes="100vw"
                        style={{
                          width: '100%',
                          height: 'auto',
                          maxWidth: 715,
                          maxHeight: slide.maxHeight,
                          borderRadius: slide.roundedCorners,
                        }}
                        draggable={false}
                        priority
                      />
                    )
                  })}
                />
              )}
              {image && !addSlideshow && (
                <Image
                  src={image?.src}
                  alt={heading}
                  width={0}
                  height={0}
                  sizes="100vw"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: 715,
                    maxHeight: image?.maxHeight,
                    borderRadius: image?.roundedCorners,
                  }}
                />
              )}
            </Box>
          </Container>
          {extraMarginBottom && <Box paddingBottom={extraMarginBottomSize} />}
        </Box>
      )}

      {useFullbleedImage && (
        <>
          <Box
            position={'relative'}
            backgroundColor={backgroundCheck ? color : undefined}
            id={addHeadingLink ? headingLink : undefined}
            {...cleanProps}
          >
            <Box className={css.imageContainer} style={mirroredLayout ? { left: 0 } : { right: 0 }}>
              {slides && (
                <Carousel
                  paginatorColor={paginatorColor}
                  paginatorPosition={paginatorPosition}
                  items={slides?.map((slide, index) => {
                    return (
                      <Image
                        key={slide.src}
                        alt={`carousel_image_${index}`}
                        draggable={false}
                        priority
                        fill
                        sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                        quality={65}
                        style={{ objectFit: 'cover' }}
                        src={slide.src}
                      />
                    )
                  })}
                />
              )}
              {image && !addSlideshow && (
                <Image
                  fill
                  src={image?.src}
                  sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                  style={{ objectFit: 'cover' }}
                  alt={`${heading} image`}
                />
              )}
              <Box style={{ height: '100%' }} position={'relative'}>
                {illustrationInImage === 'version1' ? (
                  <FloatingIllustrations version={1} />
                ) : illustrationInImage === 'version2' ? (
                  <FloatingIllustrations version={2} />
                ) : null}
              </Box>
            </Box>
            <Container className={css.container}>
              {/* Add column to fit layout */}
              {mirroredLayout && <div />}
              <Box
                paddingLeft={mirroredLayout ? { mobile: 'none', desktop: 'xxl' } : 'none'}
                paddingRight={!mirroredLayout ? { mobile: 'none', desktop: 'xxl' } : 'none'}
                paddingTop={{ mobile: 'l', desktop: 'xxxl' }}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
                justifyContent={'center'}
                gap={{ mobile: 'l', desktop: 'xxxl' }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
                  justifyContent={'flex-start'}
                  gap={'l'}
                  paddingBottom={{ mobile: 'l', desktop: 'xxl' }}
                >
                  <Box display={'flex'} flexDirection={'column'} gap={ingress ? 'l' : 'xxs'}>
                    {addIllustration && <Illustration name={illustration ?? 'camera'} width={100} />}
                    {heading && (
                      <Heading as={'h2'} fontSize={'xl'} margin={'none'} textAlign={'left'} color={invertedColor ? 'white' : 'malm0'}>
                        {heading}
                      </Heading>
                    )}
                    {ingress && (
                      <Text fontSize={'l'} fontWeight={'light'} marginBottom={'none'} color={invertedColor ? 'white' : 'malm0'}>
                        {ingress}
                      </Text>
                    )}
                    <Box display={'flex'} flexDirection={'column'} gap={'l'}>
                      {textList &&
                        textList.map((item, index) => (
                          <Box key={index} display={'flex'} flexDirection={'column'} gap={'xxs'}>
                            <Text
                              fontSize={'base'}
                              fontWeight={'semibold'}
                              marginBottom={'none'}
                              style={{ maxWidth: 430 }}
                              color={invertedColor ? 'white' : 'malm0'}
                              textAlign={item.centerText ? 'center' : 'left'}
                            >
                              {item.subheading}
                            </Text>
                            {!item.useRichTextEditor ? (
                              <Text
                                fontSize={item.showAsIngress ? 'l' : 'base'}
                                fontWeight={'light'}
                                textAlign={item.centerText ? 'center' : 'left'}
                                color={invertedColor ? 'white' : 'malm0'}
                              >
                                {item.paragraph}
                              </Text>
                            ) : (
                              <Box
                                fontSize={item.showAsIngress ? 'l' : 'base'}
                                fontWeight={'light'}
                                textAlign={item.centerText ? 'center' : 'left'}
                                color={invertedColor ? 'white' : 'malm0'}
                                dangerouslySetInnerHTML={{ __html: item.richTextParagraph }}
                              />
                            )}
                          </Box>
                        ))}
                    </Box>
                  </Box>
                  {addButton && button && (
                    <Link href={button.link} target={button.target ? '_blank' : undefined}>
                      <Button
                        variant={button.type}
                        size={button.size}
                        style={
                          (invertedColor && button?.type === 'secondary') || button?.type === 'toggle'
                            ? { borderColor: 'white', color: 'white', outline: 'none' }
                            : invertedColor && button?.type === 'primary'
                            ? { backgroundColor: 'white', color: '#222', outline: 'none' }
                            : { outline: 'none' }
                        }
                      >
                        {button.text}
                      </Button>
                    </Link>
                  )}

                  {addList && list && list.listItem && (
                    <Box display={'flex'} flexDirection={'column'} gap={'m'}>
                      <Text
                        fontSize={'l'}
                        fontWeight={'bold'}
                        className={css.listText}
                        marginBottom={'none'}
                        color={invertedColor ? 'white' : 'malm0'}
                      >
                        {list.title}
                      </Text>
                      {list.listItem.map((item, index) => (
                        <Box key={index} display={'flex'} alignItems={'center'} gap={'s'}>
                          <Box background={list.dotColor} alignItems={'center'} justifyContent={'center'} textAlign={'center'} className={css.dot}>
                            <Text fontFamily="accent" fontWeight={'semibold'} className={css.dotText}>
                              {index + list.startIndex}
                            </Text>
                          </Box>
                          <Box
                            key={index}
                            fontSize={'base'}
                            fontWeight={'light'}
                            className={css.listText}
                            color={invertedColor ? 'white' : 'malm0'}
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}

                  {children}
                </Box>
              </Box>
            </Container>
          </Box>
          {extraMarginBottom && <Box paddingBottom={extraMarginBottomSize} />}
        </>
      )}
    </>
  )
}

export default TextImageComponent
