import React, { FC } from 'react'
import { Box, Container, Heading } from 'atomic'
import { ThemeSpaces } from 'atomic/styles/theme.css'
// import * as css from './ContactForms.css'
import CollaborateForm from './forms/CollaborateForm'
import FeedbackForm from './forms/FeedbackForm'
import SupportForm from './forms/SupportForm'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type ContactFormsProps = {
  extraMarginBottom: boolean
  extraMarginBottomSize: keyof ThemeSpaces
  formType: 'collaborate' | 'support' | 'feedback'
  useTitle: boolean
  title?: string
} & BuilderProps

const ContactForms: FC<ContactFormsProps> = ({
  extraMarginBottom = false,
  extraMarginBottomSize,
  formType = 'collaborate',
  useTitle = true,
  title,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const { cmsOrLokalise } = useCmsOrTranslation()

  return (
    <>
      <Container
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={{ mobile: 'm', tablet: 'm', desktop: 'none' }}
        paddingRight={{ mobile: 'm', tablet: 'm', desktop: 'none' }}
        style={{ width: '100%', maxWidth: 940 }}
        {...cleanProps}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'l'} style={{ width: '100%' }}>
          {useTitle && (
            <Heading as={'h3'} fontSize={'xl'} marginBottom={'none'}>
              {cmsOrLokalise(title, 'web_influencer_landingpage_form_contactus')}
            </Heading>
          )}
          <Box display={'flex'} flexDirection={'column'} gap={'xxl'}>
            {formType === 'collaborate' && <CollaborateForm size={'m'} />}
            {formType === 'support' && <SupportForm />}
            {formType === 'feedback' && <FeedbackForm />}
          </Box>
        </Box>
      </Container>
      {extraMarginBottom && <Box paddingBottom={extraMarginBottomSize} />}
    </>
  )
}

export default ContactForms
