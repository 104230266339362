import { Builder, withChildren } from '@builder.io/react'
import { Box, Button, Carousel, Container } from 'atomic'
import { illustrationTypes } from 'atomic/components/atoms/illustrations/Illustration'
import { kluddIllustrationTypes } from 'atomic/components/atoms/illustrations/KluddIllustration'
import { ouTheme, themeVars } from 'atomic/styles/theme.css'
import HeadingComponent from 'components/builder_components/heading/Heading'
import Quote from 'components/builder_components/quote/Quote'
import { arrowIllustrationTypes } from 'atomic/components/atoms/illustrations/ArrowIllustration'
import ImagesComponent from 'components/builder_components/images_component/ImagesComponent'
import Illustration from 'components/builder_components/illustration/Illustration'
import TextComponent from 'components/builder_components/text_component/TextComponent'
import Usp from 'components/builder_components/usp/Usp'
import HeroProduct from 'components/builder_components/product_page_components/hero_product/HeroProduct'
import BlogPuff1 from 'components/builder_components/blog_puff_1/BlogPuff1'
import BlogPuff2 from 'components/builder_components/blog_puff_2/BlogPuff2'
import ImageSliderComponent from 'components/builder_components/image_slider_component/ImageSliderComponent'
import PricesProduct from 'components/builder_components/product_page_components/prices_product/PricesProduct'
import CoversProduct from 'components/builder_components/product_page_components/covers_product/CoversProduct'
import HeroBlog from 'components/builder_components/blog_components/hero_blog/HeroBlog'
import ButtonBox from 'components/builder_components/blog_components/button_box/ButtonBox'
import ListBlog from 'components/builder_components/blog_components/list_blog/ListBlog'
import PreFooterProduct from 'components/builder_components/product_page_components/pre_footer_product/PreFooterProduct'
import QuoteSection from 'components/builder_components/old_components/quote_section/QuoteSection'
import Reviews from 'components/builder_components/reviews/Reviews'
import HeroGeneric from 'components/builder_components/generic_page_components/hero_generic/HeroGeneric'
import TextImageComponent from 'components/builder_components/generic_page_components/text_image_component/TextImageComponent'
import Facts from 'components/builder_components/generic_page_components/facts/Facts'
import PreFooter from 'components/builder_components/generic_page_components/pre_footer/PreFooter'
import ContactForms from 'components/builder_components/generic_page_components/contact_forms/ContactForms'
import CustomizableContainer from 'components/builder_components/generic_page_components/customizable_container/CustomizableContainer'
import Kundo from 'components/storybloks/kundo/Kundo'
import Jobs from 'components/builder_components/generic_page_components/jobs/Jobs'
import Payments from 'components/builder_components/generic_page_components/payments/Payments'
import ContractSection from 'components/builder_components/generic_page_components/momsstory_components/contract_section/ContractSection'
import StatisticsContainer from 'components/builder_components/generic_page_components/momsstory_components/statistics_container/StatisticsContainer'
import LogosComponent from 'components/builder_components/generic_page_components/momsstory_components/logos_component/LogosComponent'
import Delivery from 'components/builder_components/generic_page_components/delivery/Delivery'
import SocialMedia from 'components/builder_components/social_media/SocialMedia'
import { socialMediaIconTypes } from 'atomic/components/atoms/illustrations/SocialMediaIcon'
import Countdown from 'components/builder_components/countdown/Countdown'
import ChristmasDelivery from 'components/builder_components/generic_page_components/christmas_delivery/ChristmasDelivery'

const ALLOWED_IMAGE_FILE_TYPES = ['jpeg', 'png', 'jpg', 'gif', 'webp']
const ALLOWED_VIDEO_FILE_TYPES = ['mp4', 'mov', 'webm']
const PRODUCT_TYPES = ['book', 'print']
const OU_COLORS = ouTheme.colors

const MARGIN_PADDING_INPUTS = [
  { name: 'margin', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
  { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], defaulValue: 'xxxl' },
  { name: 'marginTop', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
  { name: 'marginLeft', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
  { name: 'marginRight', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
  { name: 'padding', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
  { name: 'paddingLeft', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
  { name: 'paddingRight', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
  { name: 'paddingTop', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
  { name: 'paddingBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
]
const TEXT_INPUTS = [
  { name: 'fontSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], advanced: true },
  { name: 'textAlign', type: 'string', enum: ['left', 'center', 'right'], advanced: true },
  { name: 'color', type: 'string', enum: [...Object.keys(OU_COLORS)], advanced: true },
]
const LAYOUT_INPUTS = [...MARGIN_PADDING_INPUTS, ...TEXT_INPUTS]

export default function registerBuilderComponents(): void {
  /*
   * =======================================
   * Components explicitly for Blog
   * (blog-article)
   * =======================================
   */

  Builder.registerComponent(HeroBlog, {
    name: 'StoriesAboveTheFold',
    friendlyName: 'Hero',
    models: ['blog-article'],
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'fullBleedLayout', friendlyName: 'Use full bleed layout', type: 'boolean', required: false, defaultValue: false },
      { name: 'title', type: 'text', required: true },
      { name: 'intro', type: 'longText', required: true },
      { name: 'image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, required: true },
      {
        name: 'imagePosition',
        type: 'string',
        enum: ['center', 'top', 'left', 'right', 'bottom', 'top left', 'top right', 'bottom left', 'bottom right'],
        required: false,
        defaultValue: 'center',
      },
      { name: 'videoCheck', friendlyName: 'Use video instead of image', type: 'boolean', required: false, defaultValue: false },
      {
        name: 'videoSettings',
        type: 'object',
        showIf: `options.get('videoCheck') === true`,
        subFields: [
          { name: 'videosrc', friendlyName: 'Video', type: 'file', allowedFileTypes: ALLOWED_VIDEO_FILE_TYPES, required: true },
          { name: 'loop', type: 'boolean' },
          { name: 'muted', type: 'boolean' },
          { name: 'fileType', type: 'string', enum: ['mp4', 'mov', 'webm'], defaultValue: 'mp4' },
        ],
      },
      { name: 'guestAuthor', friendlyName: 'Add Guest Author', type: 'boolean', required: false, defaultValue: false },
      {
        name: 'guestAuthorSettings',
        friendlyName: 'Fill in Guest author details',
        helperText: 'This information will only be shown for this story. It will not be saved for future use.',
        type: 'object',
        showIf: `options.get('guestAuthor') === true`,
        subFields: [
          { name: 'name', type: 'text', required: true },
          { name: 'email', type: 'text' },
          { name: 'photo', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
        ],
      },
    ],
  })

  Builder.registerComponent(ListBlog, {
    name: 'Paragraphwithlist',
    friendlyName: 'List',
    models: ['blog-article'],
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'dotColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'blåbär2' },
      { name: 'startIndex', type: 'number', defaultValue: 1 },
      {
        name: 'list',
        type: 'list',
        subFields: [
          { name: 'title', type: 'text' },
          {
            name: 'anchorRef',
            friendlyName: 'Use as reference?',
            helperText: 'Toggle this if you want to add this heading as a reference for anchor links.',
            type: 'boolean',
            defaultValue: false,
            showIf: `model === 'blog-article'`,
          },
          {
            name: 'anchorRefName',
            friendlyName: 'Anchor Reference',
            type: 'string',
            showIf: `options.get('anchorRef') === true`,
            regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
          },
          {
            name: 'anchorLink',
            friendlyName: 'Use as anchor link?',
            helperText:
              'Toggle this if you want to add anchor link to this heading (remember to also add the anchor reference to the right element).',
            type: 'boolean',
            defaultValue: false,
            showIf: `model === 'blog-article'`,
          },
          {
            name: 'anchorLinkName',
            friendlyName: 'Anchor Link',
            type: 'string',
            showIf: `options.get('anchorLink') === true`,
            regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
          },
          { name: 'paragraph', type: 'richText' },
        ],
      },
    ],
  })

  Builder.registerComponent(ButtonBox, {
    name: 'CtaButton',
    friendlyName: 'Button Box',
    models: ['blog-article'],
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'link', type: 'string', defaultValue: '/create' },
      { name: 'text', type: 'string', defaultValue: 'Create your book' },
      { name: 'buttonText', type: 'string', defaultValue: 'Create' },
      { name: 'buttonType', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'primary' },
      { name: 'image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouTheme.colors)], defaultValue: 'blåbär2' },
    ],
  })

  /*
   * =======================================
   * Components explicitly for Product pages
   * (page)
   * =======================================
   */
  Builder.registerComponent(HeroProduct, {
    name: 'ProductHero',
    noWrap: true,
    friendlyName: 'Hero',
    models: ['page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'productType', type: 'string', enum: [...PRODUCT_TYPES] },
      { name: 'title', type: 'text', defaultValue: '' },
      { name: 'subtitle', type: 'text', defaultValue: '' },
      { name: 'startCreateButtonText', friendlyName: 'Create button text', type: 'text' },

      {
        name: 'heroSlides',
        friendlyName: 'Hero images',
        type: 'list',
        defaultValue: [],
        subFields: [{ name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES }],
      },
      {
        name: 'infoList',
        friendlyName: 'Info list background color',
        helperText: 'This is for the list where we outline the book types, paper types, book sizes etc.',
        type: 'string',
        defaultValue: 'mossa4',
        enum: [...Object.keys(OU_COLORS)],
      },
    ],
  })

  Builder.registerComponent(PricesProduct, {
    name: 'PricesSection',
    friendlyName: 'Prices',
    models: ['page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'productType', type: 'string', enum: ['book', 'print'], required: true, defaultValue: 'book' },
      { name: 'title', type: 'string', defaultValue: '' },
      { name: 'subtitle', type: 'string', defaultValue: '' },
      { name: 'customCoverImage', friendlyName: 'Add custom cover images', type: 'boolean', defaultValue: false },
      {
        name: 'book27x27cmHardCoverImage',
        friendlyName: 'Image (hardcover 27x27)',
        type: 'file',
        allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
        showIf: `options.get('customCoverImage') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'book20x20cmHardCoverImage',
        friendlyName: 'Image (hardcover 20x20)',
        type: 'file',
        allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
        showIf: `options.get('customCoverImage') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'book20x20cmSoftCoverImage',
        friendlyName: 'Image (softcover)',
        type: 'file',
        allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
        showIf: `options.get('customCoverImage') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'semiGlossPaperImage',
        friendlyName: 'Image (semi-gloss paper)',
        type: 'file',
        allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
        showIf: `(options.get('customCoverImage') === true && options.get('productType') === 'print')`,
      },
      {
        name: 'mattePaperImage',
        friendlyName: 'Image (matte paper)',
        type: 'file',
        allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
        showIf: `options.get('customCoverImage') === true && options.get('productType') === 'print'`,
      },
      { name: 'cardColors', friendlyName: 'Change card colors', type: 'boolean', defaultValue: false },
      {
        name: 'largeHardCoverCardColor',
        friendlyName: 'Card color (hardcover 27x27)',
        type: 'string',
        enum: [...Object.keys(OU_COLORS)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'mediumHardCoverCardColor',
        friendlyName: 'Card color (hardcover 20x20)',
        type: 'string',
        enum: [...Object.keys(OU_COLORS)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'softCoverCardColor',
        friendlyName: 'Card color (softcover)',
        type: 'string',
        enum: [...Object.keys(OU_COLORS)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'semiGlossCardColor',
        friendlyName: 'Card color (semi-gloss paper)',
        type: 'string',
        enum: [...Object.keys(OU_COLORS)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'print'`,
      },
      {
        name: 'matteCardColor',
        friendlyName: 'Card color (matte paper)',
        type: 'string',
        enum: [...Object.keys(OU_COLORS)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'print'`,
      },
    ],
  })

  Builder.registerComponent(CoversProduct, {
    name: 'CoversSection',
    friendlyName: 'Covers',
    models: ['page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'title', type: 'text', defaultValue: 'Cover section' },
      { name: 'buttonText', type: 'text', defaultValue: '' },
      { name: 'hardcoverUsp1', type: 'text', defaultValue: '' },
      { name: 'hardcoverUsp2', type: 'text', defaultValue: '' },
      { name: 'hardcoverUsp3', type: 'text', defaultValue: '' },
      { name: 'softcoverUsp1', type: 'text', defaultValue: '' },
      { name: 'softcoverUsp2', type: 'text', defaultValue: '' },
      { name: 'softcoverUsp3', type: 'text', defaultValue: '' },
      { name: 'customCoverImage', friendlyName: 'Add custom cover images', type: 'boolean', defaultValue: false },
      {
        name: 'hardCoverImage',
        friendlyName: 'Hard Cover image',
        helperText: 'Add custom cover image, leave empty for default image',
        type: 'file',
        allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
        showIf: `options.get('customCoverImage') === true`,
      },
      {
        name: 'softCoverImage',
        friendlyName: 'Soft Cover image',
        helperText: 'Add custom cover image, leave empty for default image',
        type: 'file',
        allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
        showIf: `options.get('customCoverImage') === true`,
      },
    ],
  })

  Builder.registerComponent(PreFooterProduct, {
    name: 'PaymentSection',
    friendlyName: 'Pre-footer',
    models: ['page'],
    inputs: [{ name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)] }],
  })

  /*
   * =======================================
   * Components explicitly for Generic pages
   * (generic-page)
   * =======================================
   */
  Builder.registerComponent(HeroGeneric, {
    name: 'HeroGeneric',
    friendlyName: 'Hero',
    models: ['generic-page'],
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      {
        name: 'layout',
        helperText:
          'Choose between three different types of layout. Default and fullbleed is used for all pages and "Illustration" is mainly for the help pages.',
        type: 'string',
        enum: ['default', 'fullbleed', 'illustration'],
        defaultValue: 'default',
        required: true,
      },
      { name: 'heading', type: 'text', showIf: `options.get('useRichTextHeading') === false` },
      {
        name: 'useRichTextHeading',
        friendlyName: 'Use Rich Text editor',
        type: 'boolean',
        defaultValue: false,
        showIf: `options.get('layout') === 'fullbleed'`,
      },
      { name: 'richTextHeading', friendlyName: 'Heading', type: 'richText', showIf: `options.get('useRichTextHeading') === true` },
      {
        name: 'uppercase',
        friendlyName: 'Make heading uppercase',
        type: 'boolean',
        defaultValue: false,
        showIf: `options.get('layout') === 'fullbleed'`,
      },
      { name: 'headingSize', friendlyName: 'Font size heading', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], defaultValue: 'xxl' },
      { name: 'intro', type: 'longText', showIf: `options.get('useRichTextIntro') === false` },
      { name: 'useRichTextIntro', friendlyName: 'Use Rich Text editor', type: 'boolean', defaultValue: false },
      { name: 'richTextIntro', friendlyName: 'Intro', type: 'richText', showIf: `options.get('useRichTextIntro') === true` },
      {
        name: 'introSize',
        friendlyName: 'Font size intro',
        type: 'string',
        enum: [...Object.keys(themeVars.fontSizes)],
        defaultValue: 'l',
      },
      { name: 'image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, showIf: `options.get('layout') !== 'illustration'` },
      {
        name: 'imagePosition',
        type: 'string',
        enum: ['center', 'top', 'bottom', 'left', 'right'],
        defaultValue: 'center',
        showIf: `options.get('layout') !== 'illustration'`,
      },
      {
        name: 'detailedImagePosition',
        helperText: 'Add a number 1-100 to position the image more precist. The number is in percent and is linked to your image position.',
        type: 'number',
        showIf: `options.get('layout') !== 'illustration'`,
      },
      {
        name: 'illustration',
        type: 'string',
        enum: [...illustrationTypes],
        defaultValue: 'heart-hug',
        showIf: `options.get('layout') === 'illustration'`,
      },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], showIf: `options.get('layout') === 'illustration'` },
      { name: 'useButton', type: 'boolean', defaultValue: false },
      {
        name: 'button',
        type: 'object',
        subFields: [
          { name: 'label', type: 'text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)] },
          { name: 'link', type: 'string' },
          { name: 'size', type: 'string', enum: ['xs', 's', 'm', 'l'] },
          { name: 'darkMode', type: 'boolean', defaultValue: false },
        ],
        showIf: `options.get('useButton') === true`,
        defaultValue: { label: 'Button', type: 'primary', link: '/photobooks', size: 'l' },
      },
      { name: 'addQuote', type: 'boolean', defaultValue: false, showIf: `options.get('layout') === 'default'` },
      {
        name: 'quote',
        friendlyName: 'Quote settings',
        helperText: 'Add a quote to the hero image',
        type: 'object',
        showIf: `options.get('addQuote') === true`,
        subFields: [
          { name: 'text', type: 'longText', defaultValue: 'This is the qoute' },
          { name: 'signature', type: 'text', defaultValue: 'Lina Andersson, CEO Once Upon' },
          { name: 'color', friendlyName: 'Text color', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'white' },
        ],
      },
      {
        name: 'addBadge',
        friendlyName: 'Add Customer Satisfaction badge',
        type: 'boolean',
        defaultValue: false,
        showIf: `options.get('layout') === 'illustration'`,
      },
    ],
  })

  Builder.registerComponent(withChildren(TextImageComponent), {
    name: 'TextImageComponent',
    friendlyName: 'Text & Image',
    childRequirements: { component: 'SocialMedia', message: 'Children of this component must be Social media component.' },
    models: ['generic-page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'extraMarginBottom',
        friendlyName: 'Add extra space in bottom',
        helperText: 'Did the margin bottom not give enough space? Use this to add more',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'extraMarginBottomSize',
        type: 'string',
        enum: [...Object.keys(themeVars.spaces)],
        defaultValue: 'xl',
        showIf: `options.get('extraMarginBottom') === true`,
      },
      {
        name: 'fullbleedLayout',
        friendlyName: 'Use fullbleed layout',
        type: 'boolean',
        defaultValue: false,
      },
      { name: 'useFullbleedImage', type: 'boolean', defaultValue: false },
      {
        name: 'mirroredLayout',
        type: 'boolean',
        helperText: 'Toggle this if you want the image to appear on the left side and the text on the right side',
      },
      {
        name: 'columnLayout',
        type: 'boolean',
        helperText: 'Toggle this if you want the image to appear above the text',
        showIf: `options.get('useFullbleedImage) === false`,
      },
      {
        name: 'backgroundCheck',
        friendlyName: 'Add background color',
        helperText: 'Toggle this if you want a background color to the section',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'color',
        type: 'string',
        enum: [...Object.keys(OU_COLORS)],
        defaultValue: 'lav4',
        showIf: `options.get('backgroundCheck') === true`,
      },
      { name: 'heading', type: 'text', defaultValue: 'This is the heading' },
      { name: 'addHeadingLink', friendlyName: 'Add anchor link to heading', type: 'boolean', defaultValue: false },
      {
        name: 'headingLink',
        friendlyName: 'Anchor link',
        type: 'text',
        showIf: `options.get('addHeadingLink') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      { name: 'invertedColor', type: 'boolean', defaultValue: false },
      { name: 'ingress', type: 'longText', required: false },
      { name: 'addIllustration', type: 'boolean', defaultValue: false },
      { name: 'illustration', type: 'string', enum: [...illustrationTypes], showIf: `options.get('addIllustration') === true` },
      {
        name: 'textList',
        type: 'list',
        subFields: [
          { name: 'subheading', type: 'text' },
          { name: 'paragraph', type: 'longText', showIf: `options.get('useRichTextEditor') === false` },
          { name: 'richTextParagraph', friendlyName: 'Paragraph', type: 'richText', showIf: `options.get('useRichTextEditor') === true` },
          { name: 'useRichTextEditor', type: 'boolean', defaultValue: false },
          { name: 'showAsIngress', friendlyName: 'Bigger font size', type: 'boolean', defaultValue: false },
          { name: 'centerText', type: 'boolean', defaultValue: false },
        ],
        required: true,
      },
      { name: 'addList', helperText: 'Toggle this if you want to add a list item to your text section', type: 'boolean', defaultValue: false },
      {
        name: 'list',
        type: 'object',
        subFields: [
          { name: 'title', type: 'text' },
          { name: 'dotColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'kantarell1' },
          { name: 'startIndex', type: 'number', defaultValue: 1 },
          {
            name: 'listItem',
            type: 'list',
            subFields: [{ name: 'text', type: 'richText' }],
          },
        ],
        showIf: `options.get('addList') === true`,
      },
      { name: 'addSlideshow', helperText: 'Use if you want more than one image', type: 'boolean', defaultValue: false },
      {
        name: 'slides',
        type: 'list',
        subFields: [
          { name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
          { name: 'roundedCorners', type: 'number' },
          { name: 'maxHeight', type: 'number' },
        ],
        showIf: `options.get('addSlideshow') === true`,
      },
      { name: 'paginatorColor', type: 'string', enum: ['light', 'dark'], defaultValue: 'dark', showIf: `options.get('addSlideshow') === true` },
      {
        name: 'paginatorPosition',
        type: 'string',
        enum: ['floating', 'bottom'],
        defaultValue: 'floating',
        showIf: `options.get('addSlideshow') === true`,
      },
      {
        name: 'image',
        type: 'object',
        showIf: `options.get('addSlideshow') === false`,
        subFields: [
          { name: 'src', friendlyName: 'Image file', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
          { name: 'maxHeight', type: 'number', defaultValue: undefined, showIf: `options.get('useFullbleedImage') === false` },
          { name: 'roundedCorners', type: 'number', showIf: `options.get('useFullbleedImage') === false` },
          { name: 'floatingImage', type: 'boolean', defaultValue: false, hideFromUI: true },
          { name: 'top', type: 'number', showIf: `options.get('floatingImage') === true` },
          { name: 'bottom', type: 'number', showIf: `options.get('floatingImage') === true` },
          { name: 'left', type: 'number', showIf: `options.get('floatingImage') === true` },
          { name: 'right', type: 'number', showIf: `options.get('floatingImage') === true` },
        ],
      },
      { name: 'addButton', type: 'boolean', defaultValue: false },
      {
        name: 'button',
        type: 'object',
        defaultValue: { text: 'This is the button text', type: 'secondary', link: '/photobooks', size: 'm' },
        showIf: `options.get('addButton') === true`,
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'This is the button text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'link', type: 'string', defaultValue: '/create' },
          { name: 'target', friendlyName: 'Open link in new window', type: 'boolean', defaultValue: false },
          { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
        ],
      },
      {
        name: 'illustrationInImage',
        friendlyName: 'Add illustration',
        helperText: 'Choose your illustration version',
        type: 'string',
        hideFromUI: true, // hidden because only used in Mom's story campaign
        enum: ['version1', 'version2'],
      },
    ],
  })

  Builder.registerComponent(Facts, {
    name: 'FunFacts',
    friendlyName: 'Facts',
    models: ['generic-page'],
    inputs: [
      { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'hjortron4' },
      {
        name: 'funFact1',
        friendlyName: 'Fact 1',
        type: 'object',
        required: true,
        defaultValue: { illustration: 'chat', kluddColor: 'blåbär3', text: '' },
        subFields: [
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'text', type: 'longText' },
        ],
      },
      {
        name: 'funFact2',
        friendlyName: 'Fact 2',
        type: 'object',
        required: true,
        defaultValue: { illustration: 'peace', kluddColor: 'kantarell2', text: '' },
        subFields: [
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'text', type: 'longText' },
        ],
      },
      {
        name: 'funFact3',
        friendlyName: 'Fact 3',
        type: 'object',
        required: true,
        defaultValue: { illustration: 'stars-1', kluddColor: 'ljung2', text: '' },
        subFields: [
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'text', type: 'longText' },
        ],
      },
    ],
  })

  Builder.registerComponent(ContactForms, {
    name: 'ContactForms',
    friendlyName: 'Contact forms',
    models: ['generic-page'],
    inputs: [
      { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'extraMarginBottom',
        friendlyName: 'Add extra space in bottom',
        helperText: 'Did the margin bottom not give enough space? Use this to add more',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'extraMarginBottomSize',
        type: 'string',
        enum: [...Object.keys(themeVars.spaces)],
        defaultValue: 'xl',
        showIf: `options.get('extraMarginBottom') === true`,
      },
      { name: 'useTitle', friendlyName: 'Add title', type: 'boolean', defaultValue: true },
      { name: 'title', type: 'text', showIf: `options.get('useTitle') === true` },
      { name: 'formType', type: 'string', enum: ['collaborate', 'support', 'feedback'] },
    ],
  })

  Builder.registerComponent(PreFooter, {
    name: 'PreFooter',
    friendlyName: 'Pre-footer',
    models: ['generic-page'],
    inputs: [
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'hjortron2' },
      { name: 'illustration', type: 'string', enum: [...illustrationTypes], defaultValue: 'diamond', showIf: `options.get('useImage') === false` },
      { name: 'useImage', friendlyName: 'Use image instead?', type: 'boolean', defaultValue: false },
      { name: 'image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, showIf: `options.get('useImage') === true` },
      { name: 'illustrationHeight', type: 'number' },
      { name: 'headline', type: 'text', defaultValue: 'This is the headline' },
      { name: 'text', type: 'longText' },
      {
        name: 'button',
        type: 'object',
        defaultValue: { text: 'This is the button text', type: 'secondary', link: '/about-us/work-with-us', size: 'm' },
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'This is the button text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'link', type: 'string', defaultValue: '/photobooks' },
          { name: 'target', friendlyName: 'Open link in new window', type: 'boolean', defaultValue: false },
          { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
        ],
      },
    ],
  })

  Builder.registerComponent(withChildren(CustomizableContainer), {
    name: 'CustomizableContainer',
    models: ['generic-page'],
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouTheme.colors)], defaultValue: 'blåbär3' },
      { name: 'addWaves', type: 'boolean', defaultValue: true },
      {
        name: 'backgroundOverlay',
        type: 'object',
        subFields: [
          { name: 'image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
          {
            name: 'imagePosition',
            type: 'string',
            enum: ['center', 'top', 'left', 'right', 'bottom', 'top left', 'top right', 'bottom left', 'bottom right'],
            defaultValue: 'center',
          },
        ],
      },
      { name: 'direction', type: 'string', enum: ['row', 'column', 'row-reverse', 'column-reverse'], defaultValue: 'row' },
      { name: 'space', friendlyName: 'Space between columns', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xl' },
    ],
    defaultChildren: [
      {
        '@type': '@builder.io/sdk:Element',
        component: { name: 'Text', options: { text: 'I am child text block!' } },
      },
    ],
  })

  // HIDDEN COMPONENTS
  // reason explained under respective component
  Builder.registerComponent(Kundo, {
    name: 'Kundo',
    friendlyName: 'Kundo',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in FAQ page
    inputs: [...MARGIN_PADDING_INPUTS],
  })

  Builder.registerComponent(Jobs, {
    name: 'JobOptions',
    friendlyName: 'Jobs',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in Work with us page
    inputs: [
      { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'title', type: 'text' },
      { name: 'deadline', type: 'text' },
    ],
  })

  Builder.registerComponent(Payments, {
    name: 'PaymentMethods',
    friendlyName: 'Payments',
    hideFromInsertMenu: true, // only used in Help pages
    models: ['generic-page'],
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'white' },
      { name: 'borderRadius', type: 'string', enum: [...Object.keys(themeVars.borderRadiuses)], defaultValue: 'm' },
      { name: 'heading', type: 'text' },
      {
        name: 'payOptions',
        type: 'list',
        subFields: [
          { name: 'title', type: 'text' },
          { name: 'description', type: 'longText' },
        ],
      },
    ],
  })

  Builder.registerComponent(Delivery, {
    name: 'DeliverySection',
    friendlyName: 'Delivery',
    hideFromInsertMenu: true, // only used in Help pages
    models: ['generic-page'],
    inputs: [...MARGIN_PADDING_INPUTS, { name: 'heading', type: 'text' }],
  })

  Builder.registerComponent(ContractSection, {
    name: 'ContractSection',
    friendlyName: 'Contract section',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in Mom's story campaign page
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'backgroundImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
      { name: 'hideBackgroundImageOnMobile', type: 'boolean', defaultValue: false },
      { name: 'image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
      { name: 'heading', type: 'text', defaultValue: 'This is the heading' },
      { name: 'addHeadingLink', friendlyName: 'Add anchor link to heading', type: 'boolean', defaultValue: false },
      {
        name: 'headingLink',
        friendlyName: 'Anchor link',
        type: 'text',
        showIf: `options.get('addHeadingLink') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      { name: 'intro', type: 'richText' },
      {
        name: 'button',
        type: 'object',
        defaultValue: { text: 'This is the button text', type: 'secondary', link: '', size: 'l' },
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'This is the button text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'link', type: 'string', defaultValue: '/create' },
          { name: 'target', friendlyName: 'Open link in new window', type: 'boolean', defaultValue: false },
          { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
        ],
      },
      {
        name: 'handwrittenText',
        friendlyName: 'Handwritten text',
        type: 'object',
        defaultValue: { text: 'This is the handwritten text', fontFamily: 'accent', fontSize: 'xl', lineHeight: 'xs' },
        subFields: [
          { name: 'text', type: 'longText', defaultValue: 'This is the quote' },
          { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent' },
          { name: 'fontSize', type: 'string', enum: [...Object.keys(ouTheme.fontSizes)], defaultValue: 'xl' },
          { name: 'lineHeight', type: 'string', enum: [...Object.keys(ouTheme.lineHeights)], defaultValue: 'xs' },
        ],
      },
    ],
  })

  Builder.registerComponent(StatisticsContainer, {
    name: 'StatisticsContainer',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in Mom's story campaign page
    inputs: [
      {
        name: 'statisticsSlides',
        type: 'list',
        subFields: [
          { name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'kantarell3' },
          { name: 'title', type: 'text' },
          { name: 'text', type: 'longText' },
        ],
      },
      { name: 'heading', type: 'text' },
      { name: 'intro', type: 'longText' },
      { name: 'source', type: 'longText' },
    ],
  })

  const fontSettingsSubfields = [
    { name: 'family', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent' },
    { name: 'size', type: 'string', enum: [...Object.keys(ouTheme.fontSizes)], defaultValue: 'xl' },
    { name: 'weight', type: 'string', enum: [...Object.keys(ouTheme.fontWeights)], defaultValue: 'normal' },
    { name: 'align', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'center' },
  ]

  Builder.registerComponent(LogosComponent, {
    name: 'LogosComponent',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in Mom's story campaign page
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'intro', type: 'longText' },
      { name: 'fontSettings', type: 'object', subFields: fontSettingsSubfields },
      {
        name: 'logos',
        type: 'list',
        subFields: [
          { name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
          { name: 'alt', type: 'text' },
          { name: 'maxHeight', type: 'number' },
          { name: 'link', type: 'text' },
        ],
      },
    ],
  })

  Builder.registerComponent(ChristmasDelivery, {
    name: 'ChristmasDelivery',
    models: ['generic-page'],
    noWrap: true,
    hideFromInsertMenu: true, // only used in Christmas Delivery page
    inputs: [...MARGIN_PADDING_INPUTS],
  })

  /*
   * =======================================
   * Components for ALL models
   * (blog-article, generic-page, page)
   * =======================================
   */
  Builder.registerComponent(HeadingComponent, {
    name: 'Heading',
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      {
        name: 'modelType',
        helperText: 'Choose here if this is a heading for a blog article or page',
        type: 'string',
        enum: ['blog', 'pages'],
        defaultValue: 'blog',
      },
      { name: 'text', type: 'text', helperText: 'This is where you write your heading' },
      { name: 'blogHeadingPosition', friendlyName: 'Text align', type: 'string', enum: ['left', 'center', 'right'] },
      { name: 'as', friendlyName: 'As tag', type: 'string', enum: ['span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5'] },
      { name: 'fontWeight', type: 'string', enum: [...Object.keys(themeVars.fontWeights)], defaultValue: 'bold' },
      { name: 'fontSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], defaultValue: 'xxl' },
      {
        name: 'anchorRef',
        friendlyName: 'Use as reference?',
        helperText: 'Toggle this if you want to add this heading as a reference for anchor links.',
        type: 'boolean',
        defaultValue: false,
        showIf: `model === 'blog-article'`,
      },
      {
        name: 'anchorRefName',
        friendlyName: 'Anchor Reference',
        type: 'string',
        showIf: `options.get('anchorRef') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      {
        name: 'anchorLink',
        friendlyName: 'Use as anchor link?',
        helperText: 'Toggle this if you want to add anchor link to this heading (remember to also add the anchor reference to the right element).',
        type: 'boolean',
        defaultValue: false,
        showIf: `model === 'blog-article'`,
      },
      {
        name: 'anchorLinkName',
        friendlyName: 'Anchor Link',
        type: 'string',
        showIf: `options.get('anchorLink') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
    ],
  })

  const imageSubFields = [
    { name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
    { name: 'objectFit', type: 'string', enum: ['contain', 'cover'], defaultValue: 'cover' },
    { name: 'alt', type: 'string', defaultValue: 'Alt text' },
    { name: 'caption', type: 'string' },
    { name: 'fractionInGrid', type: 'string', enum: ['1fr', '2fr', '3fr'], defaultValue: '1fr' },
    { name: 'maxHeight', type: 'number', defaultValue: 0 },
  ]

  Builder.registerComponent(ImagesComponent, {
    name: 'AwesomeImageComponent',
    friendlyName: 'Images',
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'fullBleed', friendlyName: 'Fullbleed images', type: 'boolean', defaultValue: false },
      { name: 'showGutter', friendlyName: 'Show space between images', type: 'boolean', defaultValue: true },
      {
        name: 'globalBorderRadius',
        friendlyName: 'Border radius',
        helperText: 'This will give your image rounded corners, higher number equals more rounded corners',
        type: 'number',
        defaultValue: 8,
      },
      {
        name: 'images',
        friendlyName: 'Images',
        type: 'list',
        subFields: imageSubFields,
      },
    ],
  })

  Builder.registerComponent(Button, {
    name: 'Button',
    inputs: [
      ...LAYOUT_INPUTS,
      { name: 'variant', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'primary' },
      { name: 'children', friendlyName: 'Label', helperText: 'This is where you write the text in your button', type: 'string' },
      { name: 'size', type: 'string', enum: ['l', 'm', 's'] },
      { name: 'blogButtonPosition', friendlyName: 'Button position', type: 'string', enum: ['left', 'center', 'right', 'none'] },
    ],
  })

  Builder.registerComponent(ImageSliderComponent, {
    name: 'SliderSection',
    friendlyName: 'Image Slider',
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'slides',
        type: 'list',
        subFields: [{ name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES }],
      },
    ],
  })

  Builder.registerComponent(Quote, {
    name: 'Quote',
    friendlyName: 'Quote',
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      {
        name: 'modelType',
        helperText: 'Choose here if this quote is for a blog article or page',
        type: 'string',
        enum: ['blog', 'pages'],
        defaultValue: 'blog',
      },
      { name: 'quote', type: 'text', defaultValue: 'Did we always have that much fun? Did we never fight? Was there always a party?' },
      { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent', showIf: `options.get('modelType') === 'blog'` },
      {
        name: 'fontSize',
        type: 'string',
        enum: [...Object.keys(ouTheme.fontSizes)],
        defaultValue: 'xl',
        showIf: `options.get('modelType') === 'blog'`,
      },
      { name: 'textAlign', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'left' },
      {
        name: 'lineHeight',
        type: 'string',
        enum: [...Object.keys(ouTheme.lineHeights)],
        defaultValue: 'l',
        showIf: `options.get('modelType') === 'blog'`,
      },
      {
        name: 'illustration',
        friendlyName: 'Image/Illustration',
        type: 'file',
        allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
        showIf: `options.get('modelType') === 'blog'`,
      },
      { name: 'illustrationPosition', type: 'string', enum: ['left', 'right'], defaultValue: 'left', showIf: `options.get('modelType') === 'blog'` },
      {
        name: 'fontWeight',
        type: 'string',
        enum: [...Object.keys(themeVars.fontWeights)],
        defaultValue: 'bold',
        showIf: `options.get('modelType') === 'pages'`,
      },
      {
        name: 'backgroundColor',
        type: 'string',
        enum: [...Object.keys(OU_COLORS)],
        defaultValue: 'mossa4',
        showIf: `options.get('modelType') === 'pages'`,
      },
      { name: 'signature', type: 'text', showIf: `options.get('modelType') === 'pages'` },
    ],
  })

  Builder.registerComponent(SocialMedia, {
    name: 'SocialMedia',
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      {
        name: 'direction',
        helperText: 'Direction in which the icons are displayed',
        type: 'string',
        enum: ['vertical', 'horizontal'],
        defaultValue: 'horizontal',
      },
      { name: 'iconSize', type: 'string', enum: ['s', 'm', 'l'], defaultValue: 'm' },
      { name: 'iconSpacing', helperText: 'Space between each icon', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xs' },
      { name: 'iconColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'malm0' },
      {
        name: 'socialLink',
        friendlyName: 'Links',
        helperText: 'Add your social media links here',
        type: 'list',
        subFields: [
          { name: 'label', type: 'text' },
          { name: 'link', type: 'url' },
          { name: 'icon', type: 'string', enum: [...socialMediaIconTypes] },
        ],
      },
    ],
  })

  Builder.registerComponent(Countdown, {
    name: 'Countdown',
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'fullbleed', friendlyName: 'Use fullbleed layout', type: 'boolean', defaultValue: true },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
      { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent' },
      { name: 'fontSize', type: 'string', enum: [...Object.keys(ouTheme.fontSizes)] },
      { name: 'invertedTextColor', helperText: 'Toggle this if you want white text color', type: 'boolean' },
      { name: 'contentAlignment', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'center' },
      { name: 'targetDate', type: 'date', helperText: 'Pick start date for event', required: true },
      { name: 'targetText', type: 'text', helperText: 'This text is shown under the countdown' },
      { name: 'addEndDate', type: 'boolean', defaultValue: false },
      { name: 'eventEndDate', helperText: 'Pick end date for event', type: 'date', showIf: `options.get('addEndDate') === true` },
      { name: 'eventEndText', type: 'text', helperText: 'Text to under the countdown', showIf: `options.get('addEndDate') === true` },
      { name: 'useTextGradient', helperText: 'Adds a fading gradient to the text (not the countdown)', type: 'boolean', defaultValue: false },
    ],
  })

  /*
   * =======================================
   * Components for multiple models
   * (generic-page, page, blog-articles)
   * =======================================
   */
  Builder.registerComponent(TextComponent, {
    name: 'AwesomeParagraphs',
    friendlyName: 'Text',
    models: ['blog-article', 'generic-page'],
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      {
        name: 'modelType',
        helperText: 'Choose here if this is a text for a blog article or page',
        type: 'string',
        enum: ['blog', 'pages'],
        defaultValue: 'blog',
      },
      { name: 'dualColumns', type: 'boolean', defaultValue: false },
      {
        name: 'isPreamble',
        friendlyName: 'Display as ingress',
        helperText: 'This option makes the text bigger and with more line height',
        type: 'boolean',
        defaultValue: false,
      },
      { name: 'headline', type: 'text', helperText: 'This is where you write your headline' },
      { name: 'headlineVariant', friendlyName: 'Type of headline', type: 'string', enum: ['heading', 'subheading'], defaultValue: 'heading' },
      { name: 'headlinePosition', type: 'string', enum: ['left', 'center', 'right'] },
      {
        name: 'anchorRef',
        friendlyName: 'Use as reference?',
        helperText: 'Toggle this if you want to add this heading as a reference for anchor links.',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'anchorRefName',
        friendlyName: 'Anchor Reference',
        type: 'string',
        showIf: `options.get('anchorRef') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      {
        name: 'anchorLink',
        friendlyName: 'Use as anchor link?',
        helperText: 'Toggle this if you want to add anchor link to this heading (remember to also add the anchor reference to the right element).',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'anchorLinkName',
        friendlyName: 'Anchor Link',
        type: 'string',
        showIf: `options.get('anchorLink') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      { name: 'paragraphContent', type: 'richText' },
      { name: 'bodyTextAlign', friendlyName: 'Text align', type: 'string', enum: ['left', 'center', 'right'] },
    ],
  })

  Builder.registerComponent(Usp, {
    name: 'SellingPointsSection',
    friendlyName: 'USP',
    models: ['page', 'generic-page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'usp1',
        friendlyName: 'USP 1',
        type: 'object',
        defaultValue: { color: 'lav4', title: '', body: '', kluddColor: 'mossa2', illustration: 'flower' },
        subFields: [
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'title', type: 'text' },
          { name: 'body', type: 'longText' },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
        ],
      },
      {
        name: 'usp2',
        friendlyName: 'USP 2',
        type: 'object',
        defaultValue: { color: 'lav4', title: '', body: '', kluddColor: 'kantarell1', illustration: 'delivery' },
        subFields: [
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'title', type: 'text' },
          { name: 'body', type: 'longText' },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
        ],
      },
      {
        name: 'usp3',
        friendlyName: 'USP 3',
        type: 'object',
        defaultValue: { color: 'lav4', title: '', body: '', kluddColor: 'ljung2', illustration: 'hearts' },
        subFields: [
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'title', type: 'text' },
          { name: 'body', type: 'longText' },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
        ],
      },
      {
        name: 'usp4',
        friendlyName: 'USP 4',
        type: 'object',
        defaultValue: { color: 'lav4', title: '', body: '', kluddColor: 'blåbär2', illustration: 'book' },
        subFields: [
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'title', type: 'text' },
          { name: 'body', type: 'longText' },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
        ],
      },
    ],
  })

  const arrowIllustrationSubFields = [
    { name: 'arrowDirection', type: 'string', enum: ['up', 'down'], defaultValue: 'down' },
    {
      name: 'arrowRotation',
      friendlyName: 'Rotate arrow',
      helperText: 'Add the degrees you want the illustration to rotate by',
      type: 'range',
      defaultValue: 0,
      min: 0,
      max: 100,
    },
  ]

  Builder.registerComponent(Illustration, {
    name: 'IllustrationSection',
    friendlyName: 'Illustration',
    models: ['generic-page', 'page'],
    inputs: [
      ...MARGIN_PADDING_INPUTS,
      { name: 'useImage', friendlyName: 'Use image instead', type: 'boolean', defaultValue: false },
      {
        name: 'image',
        type: 'object',
        showIf: `options.get('useImage') === true`,
        subFields: [
          { name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
          { name: 'alt', type: 'text', helperText: 'Is used for SEO, UX, and accessibility' },
          { name: 'width', type: 'number', defaultValue: 100 },
          { name: 'height', type: 'number', defaultValue: 100 },
        ],
      },
      {
        name: 'illustration',
        type: 'string',
        enum: [...illustrationTypes, ...arrowIllustrationTypes, ...kluddIllustrationTypes],
        defaultValue: 'arrow-5',
        showIf: `options.get('useImage') === false`,
      },
      { name: 'illustrationColor', friendlyName: 'Color', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'malm0' },
      { name: 'illustrationPosition', friendlyName: 'Position', type: 'string', enum: ['left', 'right', 'center'], defaultValue: 'left' },
      { name: 'illustrationSize', friendlyName: 'Size', type: 'string', enum: ['s', 'm', 'l'], defaultValue: 'm' },
      { name: 'arrowOptions', type: 'object', subFields: arrowIllustrationSubFields, showIf: `options.get('illustration').includes('arrow')` },
    ],
  })

  Builder.registerComponent(withChildren(Carousel), {
    name: 'Carousel',
    models: ['page', 'generic-page'],
    inputs: [
      ...LAYOUT_INPUTS,
      { name: 'paginatorPosition', type: 'string', enum: ['floating', 'bottom'], defaultValue: 'bottom' },
      { name: 'paginatorColor', type: 'string', enum: ['dark', 'light'], defaultValue: 'dark' },
    ],
    defaultChildren: [
      {
        '@type': '@builder.io/sdk:Element',
        component: { name: 'Text', options: { text: 'I am child text block!' } },
      },
      {
        '@type': '@builder.io/sdk:Element',
        component: { name: 'Text', options: { text: 'I am child text block!' } },
      },
    ],
  })

  Builder.registerComponent(Reviews, {
    name: 'ReviewsSection',
    friendlyName: 'Reviews',
    models: ['page', 'generic-page'],
    inputs: [{ name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' }],
  })

  Builder.registerComponent(withChildren(Container), {
    name: 'OUContainer',
    models: ['page', 'generic-page'],
    friendlyName: 'Container (Utopia)',
    inputs: [
      ...LAYOUT_INPUTS,
      { name: 'background', type: 'string', enum: [...Object.keys(OU_COLORS)] },
      { name: 'customWidth', friendlyName: 'Max width', type: 'number' },
    ],
  })

  Builder.registerComponent(withChildren(Box), {
    name: 'OUBox',
    models: ['page', 'generic-page'],
    friendlyName: 'Box (Utopia)',
    inputs: [
      ...LAYOUT_INPUTS,
      { name: 'background', type: 'string', enum: [...Object.keys(OU_COLORS)] },
      { name: 'display', type: 'string', enum: ['block', 'flex'], defaultValue: 'flex' },
      { name: 'flexDirection', type: 'string', enum: ['row', 'column', 'row-reverse', 'column-reverse'], defaultValue: 'row' },
      { name: 'gap', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'm' },
      { name: 'maxWidth', type: 'number' },
    ],
  })

  // HIDDEN COMPONENTS
  // reason explained under respective component
  Builder.registerComponent(BlogPuff1, {
    name: 'TipsSection',
    models: ['page', 'generic-page'],
    friendlyName: 'Blog puff 1',
    hideFromInsertMenu: true, // name is not as self-explanatory as needed, used as template instead to get visual representation
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'kantarell4' },
      { name: 'title', type: 'longText', defaultValue: '' },
      { name: 'circleText', type: 'text', defaultValue: 'News' },
      { name: 'circleColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'blåbär2' },
      { name: 'description', type: 'longText', defaultValue: '' },
      { name: 'linkHref', type: 'url', defaultValue: '/weddingbooks' },
      { name: 'linkText', type: 'text', defaultValue: '' },
      { name: 'smallImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
      { name: 'coverImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
      { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
      { name: 'illustrationBoxColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
      { name: 'illustrationBoxText', type: 'longText', defaultValue: '' },
    ],
  })

  Builder.registerComponent(BlogPuff2, {
    name: 'PromoComponent',
    friendlyName: 'Blog puff 2',
    hideFromInsertMenu: true, // name is not as self-explanatory as needed, used as template instead to get visual representation
    models: ['generic-page', 'page'],
    inputs: [
      { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'extraMarginBottom',
        friendlyName: 'Add extra space in bottom',
        helperText: 'Did the margin bottom not give enough space? Use this to add more',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'extraMarginBottomSize',
        type: 'string',
        enum: [...Object.keys(themeVars.spaces)],
        defaultValue: 'xl',
        showIf: `options.get('extraMarginBottom') === true`,
      },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'lav3' },
      {
        name: 'mirroredLayout',
        helperText: 'Toggle this if you want the image to appear on the right side and the text on the left side',
        type: 'boolean',
        defaultValue: false,
      },
      { name: 'videoCheck', friendlyName: 'Use video instead of image', type: 'boolean', defaultValue: false },
      {
        name: 'image',
        type: 'object',
        showIf: `options.get('videoCheck') === false`,
        subFields: [
          { name: 'file', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
          { name: 'imagePosition', type: 'string', enum: ['center', 'top', 'bottom', 'left', 'right'], required: false, defaultValue: 'center' },
          { name: 'imageAltText', type: 'text', helperText: 'Is used for SEO, UX, and accessibility' },
        ],
      },
      {
        name: 'video',
        type: 'object',
        showIf: `options.get('videoCheck') === true`,
        subFields: [
          { name: 'file', type: 'file', allowedFileTypes: ALLOWED_VIDEO_FILE_TYPES },
          { name: 'posterImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
          { name: 'loop', type: 'boolean' },
          { name: 'muted', type: 'boolean' },
          { name: 'fileType', type: 'string', enum: ['mp4', 'mov', 'webm'], defaultValue: 'mp4' },
        ],
      },
      { name: 'subHeadline', type: 'text', hideFromUI: true },
      {
        name: 'subTitle',
        type: 'object',
        subFields: [
          { name: 'text', type: 'text' },
          { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'primary' },
          { name: 'fontSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], defaultValue: 'base' },
          { name: 'fontWeight', type: 'string', enum: [...Object.keys(themeVars.fontWeights)], defaultValue: 'light' },
          { name: 'addUnderline', type: 'boolean', defaultValue: false },
        ],
      },
      { name: 'headline', type: 'text' },
      { name: 'text', type: 'longText' },
      { name: 'invertedColor', type: 'boolean', defaultValue: false },
      { name: 'addList', type: 'boolean', defaultValue: false },
      {
        name: 'list',
        type: 'object',
        subFields: [
          { name: 'dotColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'blåbär2' },
          { name: 'startIndex', type: 'number', defaultValue: 1 },
          {
            name: 'listItem',
            type: 'list',
            subFields: [
              { name: 'title', type: 'text' },
              { name: 'text', type: 'longText' },
            ],
          },
        ],
        showIf: `options.get('addList') === true`,
      },
      { name: 'useButton', type: 'boolean', defaultValue: true },
      {
        name: 'button',
        type: 'object',
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'This is the button text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'link', type: 'string', defaultValue: '/create' },
          { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
        ],
        defaultValue: { text: 'This is the button text', type: 'secondary', link: '/create', size: 'm' },
        showIf: `options.get('useButton') === true`,
      },
    ],
  })

  Builder.registerComponent(QuoteSection, {
    name: 'QuoteSection',
    friendlyName: 'Quote',
    hideFromInsertMenu: true, // not in use anymore, use component Quote instead. Needs to be kept for backwards compatibility
    models: ['page', 'generic-page'],
    inputs: [
      ...LAYOUT_INPUTS,
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'transparent' },
      { name: 'quote', type: 'longText' },
      { name: 'fontWeight', type: 'string', enum: [...Object.keys(themeVars.fontWeights)], defaultValue: 'bold' },
      { name: 'textAlign', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'center' },
      { name: 'signature', type: 'text' },
    ],
  })
}
